<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('stock_management.certificate')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-row class="mt-4">
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                  label-for="office_id"
                  >
                    <template v-slot:label>
                      {{ $t('stock_management.regional_office') }}
                    </template>
                    <b-form-select
                      plain
                      :disabled="lock_office"
                      id="office_id"
                      :options="officeList"
                      v-model="search.office_id"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Contract No" vid="contract_no_id" rules="required|min_value:1">
                  <b-form-group
                    label-for="contract_no_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('stock_management.contract_no')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.contract_no_id"
                    :options="contractNumberList"
                    id="contract_no_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
              <b-col lg="4" sm="4">
                <ValidationProvider name="Fiscal Year id" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-for="fiscal_year_id"
                    slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                          {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      @change="fiscalYearCahnge($event)"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- acknowledgement type -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Acknowledgement Type" vid="acknowled_type_id" rules="required|min_value:1">
                  <b-form-group
                    label-for="acknowled_type_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('stock_management.acknowledgement_type')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="search.acknowled_type_id"
                    :options="acknowledgementType"
                    id="acknowled_type_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- start date -->
              <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="From date" vid="from_date" rules="required" v-slot="{ errors }">
                  <b-form-group label-for="from_date">
                    <template v-slot:label>
                      {{ $t('globalTrans.date_from') }} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="from_date"
                      class="form-control"
                      v-model="search.from_date"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- end date -->
              <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="To date" vid="to_date" rules="required" v-slot="{ errors }">
                  <b-form-group label-for="to_date">
                    <template v-slot:label>
                      {{ $t('globalTrans.date_to') }} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                      id="to_date"
                      class="form-control"
                      v-model="search.to_date"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="$t('globalTrans.select')"
                      :config="dateConfig"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="text-right">
              <b-col>
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{$t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <body-card style="overflow-x: clip;">
        <b-row class="text-right mb-5 mt-5">
          <b-col>
              <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{  $t('globalTrans.print') }}
              </b-button>
          </b-col>
        </b-row>
      <!-- table section start -->
       <b-overlay :show="loader">
      <div>
        <div class="row" style="width: 75%;margin: auto; margin-top:5px">
          <div class="col-2" style="text-align: center;">
            <img src="@/assets/images/logo.png" class="img-fluid" alt="" style="width: 70px;">
          </div>
          <div class="col-8" style="text-align:center; margin-top: 10px;">
            <p style="font-weight: 600;font-size: 18px;margin-top: 5px;">{{$t('tcb_report.government_text')}}</p>
            <p style="font-weight: 500;font-size: 16px;margin-top: 3px;">{{$t('tcb_report.trading_corporation_of_bangladesh')}}</p>
            <p style="font-weight:500;font-size: 14px;margin-top: 3px;">{{ getOfficeName(search.office_id) }}</p>
          </div>
          <div class="col-2" style="text-align:center">
            <img src="@/assets/images/tcb_logo.png" class="img-fluid" alt="" style="width: 70px;">
          </div>
      </div>
      <div style="text-align: center;">
        <span style="border-bottom: 1px solid black;">
          {{ $t('stock_management.certificate') }}
        </span>
      </div>
      <div class="p-3" v-if="stockData.length">
          <p v-if="this.$i18n.locale === 'bn'">
            এই মর্মে প্রত্যয়ন করা যাচ্ছে যে, চুক্তি নং {{ getContractNo(search.contract_no_id, search.fiscal_year_id) }} এর অধীন স্থানীয় ভাবে ক্রয়কৃত <b>{{$n(totalQuantity)}}</b> {{ getKgOrLetter(search.contract_no_id) }} {{ getSupplierName(search.contract_no_id) }} গত {{ search.from_date|dateFormat }} তারিখ হতে {{ search.to_date|dateFormat }} তারিখ পর্যন্ত {{$n(stockData.length)}} টি ট্রাকের মাধ্যমে {{getOfficeName(search.office_id) }}, গুদামে গৃহিত হয়েছে। বিবরণ নিম্নরুপঃ
          </p>
          <p v-else>
            It is hereby certified that {{$n(totalQuantity)}} {{ getKgOrLetter(search.contract_no_id) }} {{ getSupplierName(search.contract_no_id) }} procured locally under Contract No. {{ getContractNo(search.contract_no_id, search.fiscal_year_id) }} from {{ search.from_date|dateFormat }} to {{ search.to_date|dateFormat }} through {{$n(stockData.length)}} trucks to {{getOfficeName(search.office_id) }}, Received in warehouse. The description is as follows:
          </p>
      </div>
        <div class="p-3">
          <table style="width:100%;padding:10px;margin-top:10px;" v-if="stockData.length">
          <tr>
            <th>{{$t('globalTrans.sl_no')}}</th>
            <th>{{$t('globalTrans.date')}}</th>
            <th>{{$t('stock_management.truck_no')}}</th>
            <th>{{$t('component_settings.challan_no')}}</th>
            <th>{{$t('stock_management.supply_quantity')}}</th>
            <th>{{$t('stock_management.receive_quantity')}}</th>
            <th>{{$t('stock_management.shortage_reasons')}}</th>
            <th>{{$t('stock_management.shortage_quantity')}}</th>
            <th>{{$t('globalTrans.comments')}}</th>
          </tr>
            <tr v-for="(value, index) in stockData" :key="index">
              <td>{{ $n(index+1) }}</td>
              <td> {{ value.stock_in_date | dateFormat  }}</td>
              <td>{{ value.truck_no }}</td>
              <td>{{ value.challan_no }}</td>
              <td class="text-right">{{ $n(value.supply_quantity) }}</td>
              <td class="text-right">{{ $n(value.quantity) }}</td>
              <td class="text-right">{{ getShortageReasons(value.shortage_reasons_id) }}</td>
              <td class="text-right">{{ $n(value.shortage_quantity) }}</td>
              <!-- <td class="text-right">{{ $n(parseFloat(value.supply_quantity)-parseFloat(value.quantity)) }}</td> -->
              <td></td>
            </tr>
             <tr>
              <td colspan="4" class="text-right"><b>{{ $n(stockData.length) +' '+ $t('stock_management.truck_total') }} =</b></td>
              <td class="text-right">{{ $n(totalSupplyQuantity) }}</td>
              <td class="text-right">{{ $n(totalQuantity) }}</td>
              <td></td>
              <td class="text-right">{{ $n(totalDifference) }}</td>
              <td></td>
            </tr>
        </table>
        </div>
        <div v-if="stockData.length" class="p-3" >
          <p>
          <span v-if="this.$i18n.locale === 'bn'"> সদয় অবগতি ও পরবর্তী কার্যক্রম গ্রহনের জন্য প্রেরন করা হলো।</span>
          <span v-else>Kindly informed and forwarded for further action.</span>
        </p>
        <p style="margin-top: 50px;">
          <span v-if="this.$i18n.locale === 'bn'">অফিস প্রধান</span>
          <span v-else>Head of Office</span><br>
          <span>{{ getOfficeName(search.office_id) }}</span>

        </p>
        </div>
      </div>
        </b-overlay>
    </body-card>
  </div>
</template>
<script>
import ModalBaseMasterList from '@/mixins/list'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { stockCertificateList, getAcknowledData } from '../../../api/routes'
// import ReportHeading from '../../components/ReportHeading.vue'
export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        acknowled_type_id: 0,
        fiscal_year_id: 0,
        from_date: '',
        to_date: '',
        month: 0,
        contract_no_id: 0,
        office_id: 0,
        item: 0,
        warehouseIds: '',
        item_category: 0
      },
     AcknowledgementTypeList: [
        { value: 2, text: '1st part', text_en: '1st part', text_bn: '১ম খণ্ড' },
        { value: 3, text: '2nd part', text_en: '2nd part', text_bn: '২য় খণ্ড' },
        { value: 4, text: '3rd part', text_en: '3rd part', text_bn: '৩য় খণ্ড' },
        { value: 5, text: '4th part', text_en: '4th part', text_bn: '৪র্থ খণ্ড' },
        { value: 6, text: '5th part', text_en: '5th part', text_bn: '৫ম খণ্ড' },
        { value: 7, text: '6th part', text_en: '6th part', text_bn: '৬ষ্ঠ খণ্ড' },
        { value: 1, text: 'Full.', text_en: 'Full.', text_bn: 'সম্পূর্ণ' }
      ],
      warehouseList: [],
      dateConfig: {
        static: true,
        enable: [{ from: '1970-01-01', to: '9999-12-31' }],
        disable: []
      },
      stockData: [],
      contractNumberList: [],
      fiscalYear: {},
      lock_office: false,
      show: false,
      loader: false
    }
  },
  created () {
    this.getContractAbailable()
    this.search.fiscal_year_id = this.currentFiscalYearId
    this.fiscalYearCahnge(this.currentFiscalYearId)
    if (!(this.$store.state.Auth.authUser.user_id === 1 || this.$store.state.Auth.authUser.org_admin === 2)) {
      this.search.office_id = this.$store.state.Auth.authUser.office_detail.office_id
    }
  },
  watch: {
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.lock_office === false) {
          this.warehouseList = this.$store.state.LicenseRegistrationService.commonObj.warehouseList.filter(item => item.status === 1 && item.office_id === newVal)
          this.search.warehouseIds = this.warehouseList.map(warehouse => warehouse.value)
          this.search.acknowled_type_id = 0
        }
      }
    },
    'search.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.acknowled_type_id = 0
      }
    },
    'search.contract_no_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.acknowled_type_id = 0
      }
    },
    'search.acknowled_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.from_date = ''
        this.search.to_date = ''
        this.dateConfig.enable = [{ from: '1970-01-01', to: '9999-12-31' }]
        if (newVal > 1) {
          this.getAckonwledData()
        }
      }
    }
  },
  computed: {
    acknowledgementType: function () {
      return this.AcknowledgementTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    officeList () {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === 7 && item.is_regional_office === 1)
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: this.EngBangNum(item.text_bn) }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    totalSupplyQuantity () {
      return this.stockData.reduce((sum, value) => {
        return sum + parseFloat(value.supply_quantity || 0)
      }, 0)
    },
    totalQuantity () {
      return this.stockData.reduce((sum, value) => {
        return sum + parseFloat(value.quantity || 0)
      }, 0)
    },
    totalDifference () {
      return this.stockData.reduce((sum, value) => {
        return sum + parseFloat(value.shortage_quantity || 0)
      }, 0)
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    }
  },
  methods: {
    getContractAbailable () {
      const contractList = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.filter(item => item.status === 1)
      this.contractNumberList = contractList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.contract_no + '(' + item.text_bn + ')' }
        } else {
            return { value: item.value, text: item.contract_no + '(' + item.text + ')' }
        }
      })
    },
    getShortageReasons (itemId) {
        const itemObj = this.$store.state.LicenseRegistrationService.commonObj.shortageReasonsList.find(item => item.value === itemId)
          if (itemObj !== undefined) {
              if (this.$i18n.locale === 'bn') {
                  return itemObj.text_bn
              } else {
                  return itemObj.text_en
              }
          } else {
            return ''
          }
      },
    totalConslussion (data) {
      let total = 0
      data.map(function (report, index) {
        total += report.total_stock_in - (report.total_stock_out + report.total_stock_out_pending + report.total_on_transport + report.total_truck_distribution + report.total_store_distribution)
      })
      return total
    },
    async getAckonwledData () {
        let result = null
        result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getAcknowledData, this.search)
        if (result.success) {
          if (result.single) {
            const ackData = result.data
            if (ackData !== undefined) {
              this.dateConfig.enable = [
                { from: ackData.from_date, to: ackData.to_date }
              ]
              this.search.from_date = ackData.from_date
              this.search.to_date = ackData.to_date
            }
          } else {
            const ackDatas = result.data
            if (ackDatas && ackDatas.length > 0) {
              const disableArr = ackDatas.map(item => {
                const fromDate = new Date(item.from_date)
                fromDate.setDate(fromDate.getDate() - 1)
                return {
                    from: fromDate,
                    to: new Date(item.to_date)
                }
              })

              this.dateConfig.enable = [
                  function (date) {
                      return !disableArr.some(range =>
                          date >= new Date(range.from) && date <= new Date(range.to)
                      )
                  }
              ]
            }
          }
        } else {
          this.dateConfig.enable = [{ from: '1970-01-01', to: '9999-12-31' }]
        }
    },

    async searchData () {
      this.loader = true
      const isValid = await this.$refs.form.validate()
      if (isValid) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.getData(licenseRegistrationServiceBaseUrl, stockCertificateList, this.search)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
          this.loader = false
          this.show = true
          this.stockData = result.data
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, stockCertificateList, params)
      var blob = new Blob([result], {
            type: 'application/pdf'
        })
        this.loading = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    getFiscalYear (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
          return ''
      }
    },
    fiscalYearCahnge (event) {
      this.fiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === event)
      this.search.start_date = this.fiscalYear.start_date
      this.search.end_date = this.fiscalYear.end_date
      this.dateConfig = Object.assign({}, this.dateConfig, { minDate: this.fiscalYear.start_date, maxDate: this.fiscalYear.end_date })
    },
    EngBangNum (n) {
        if (this.$i18n.locale === 'bn') {
          return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
        } else {
          return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
        }
      },
    getContractNo (conId, fyId) {
      const contract = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.find(item => item.value === parseInt(conId))
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(fyId))
      if (typeof contract !== 'undefined' && typeof data !== 'undefined') {
          return this.$i18n.locale === 'bn' ? this.$n(contract.contract_no) + '/' + data.text_bn : this.$n(contract.contract_no) + '/' + data.text_en
      } else {
          return ''
      }
    },
    getSupplierName (conId) {
      const contract = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.find(item => item.value === parseInt(conId))
      if (typeof contract !== 'undefined') {
          return this.$i18n.locale === 'bn' ? contract.text_bn : contract.text_en
      } else {
          return ''
      }
    },
    getKgOrLetter (conId) {
      const contract = this.$store.state.LicenseRegistrationService.commonObj.contractNumberList.find(item => item.value === parseInt(conId))
      if (typeof contract !== 'undefined') {
        return this.$i18n.locale === 'bn' ? contract.item_id === 10 ? 'লিটার' : 'কেজি' : contract.item_id === 10 ? 'Letter' : 'Kg'
      } else {
          return ''
      }
    }
  }
}
</script>
<style>
table, th, td {
    border:1px solid black;
    text-align: center;
    padding: 5px;
    font-size: 12px;
}
</style>
